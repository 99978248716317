
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import { Tag, Skeleton } from 'antd'
import { Icon, Row, Col } from 'react-onsenui';
import { Transition } from '../App';
import { format } from 'timeago.js';
import { notification } from 'antd';
import Title from '../components/Title';
import LogInfo from '../components/LogInfo';
import Button from '../components/Button';
import { useTranslation, Trans } from "react-i18next";
import CustomAlertDialog from './Settings/AlertDialog';
import { Checkbox } from 'react-onsenui';

const LogsColors = {
    blocking: "3px solid #a14838",
    detecting: "3px solid #e58900",
    passing: "3px solid #22d933",
    bypassing: "3px solid #4974a5",
}

const filterOptions = ['passing', 'blocking', 'detecting'];

export function Audit({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [options, setOptions] = useState({ group: true })
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [raw, setRaw] = useState([])
    const [blackwhite, setBlackwhite] = useState(false)
    const [collapsedLogs, setCollapsedLogs] = useState([])
    const [domains, setDomains] = useState([]);
    const [blockDomains, setBlockDomains] = useState([]);
    const [alertDialog, setAlertDialog] = useState(<></>);
    const [table, setTable] = useState([])
    const [tags, setTags] = useState([])
    const [filters, setFilters] = useState(filterOptions);

    const collapseLog = (current) => {
        const allLogs = [...table];
        const _log = allLogs.find(log => log.key === current.key);
        _log.collapsed = !current.collapsed;
        setCollapsedLogs(allLogs);
    };

    useEffect(() => {
        var stop = false
        var timer = null

        async function updateStats() {
            timer = null
            setLoading(true)
            const ret = await kernel.api.get(`/dohzel/logs/device?policies=${filters.join(',')}`)
            if (!ret || ret.error) {
                if (stop === true)
                    return

                return (timer = setTimeout(updateStats, 1000))
            }

            setTable(ret.data.data)

            if (stop === true)
                return

            timer = setTimeout(() => setLoading(false), 1000)

        }
        updateStats()

        return (() => {
            stop = true
            if (timer)
                clearTimeout(timer)
        })
    }, [loaded, refresh, options, filters])

    useEffect(() => {
        Transition(['#page'], () => { }, "inRight")
    }, [loaded]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?list=allow`);
            if (!ret || ret.error) {
                return;
            }
            setDomains(ret.data)
        }
        fetch();
    }, [loaded]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?list=block`);
            if (!ret || ret.error) {
                return;
            }
            setBlockDomains(ret.data)
        }
        fetch();
    }, [loaded]);

    useEffect(() => {
        ( async () => {
            const response = await kernel.api.get('/dohzel/tags')
            if (response.error) {
                return;
            }
            setTags(response.data);
        })()
    }, []);

    function rowClick(item) {

        if (!item.hasOwnProperty("selected"))
            item.selected = true
        else
            item.selected = !item.selected

        if (item.selected === true)
            setBlackwhite(true)
        else {
            var found = false
            for (var item of table) {
                if (item.selected === true)
                    found = true
            }

            if (found === true) setBlackwhite(true)
            else setBlackwhite(false)
        }
        setTable([...table])
    }

    function clean() {
        for (var item of table)
            item.selected = false
        setBlackwhite(false)
        setTable([...table])
    }

    function selectAll() {
        for (var item of table)
            item.selected = true
        setBlackwhite(true)
        setTable([...table])
    }

    function formatText(item) {
        function flow(text) {
            switch (text) {
                case "blocking": return ("Blocked")
                case "detecting": return ("Detected")
                case "bypassing": return ("Bypassed")
            }
        }
        function type(text) {
            switch (text) {
                case "request": return ("DNS Request")
            }
        }

        if (item.occurs > 1) {
            return (<span style={{}}>{item.occurs} {flow(item.flow)} {type(item.type)}s - {format(item.date)}</span>)
        }
        return (<span style={{}}>{type(item.type)} {flow(item.flow)} from {item.address} - {format(item.date)}</span>)
    }

    const add = async (input, type='allow', item) => {
        if(type == 'allow' && blockDomains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain)) {
            collapseLog(item)
            setAlertDialog(
                <CustomAlertDialog title={t("Audit.allowConflitTitle")} subTitle={t("Audit.allowConflitContent")} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setAlertDialog={setAlertDialog}
                    buttons={[
                        {
                            label: t("NoConfirm"),
                            color: 'red',
                            func: () => setAlertDialog(<></>)
                        },
                        {
                            label: t("YesConfirm"),
                            func: () => {
                                updateList(item, type);
                                saveList(input, type);
                                setAlertDialog(<></>)
                            }
                        }
                    ]}
                />
            )
        }
        else if(type == 'block' && domains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain)) {
            collapseLog(item)
            setAlertDialog(
                <CustomAlertDialog title={t("Audit.blockConflitTitle")} subTitle={t("Audit.blockConflitContent")} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setAlertDialog={setAlertDialog}
                    buttons={[
                        {
                            label: t("NoConfirm"),
                            color: 'red',
                            func: () => setAlertDialog(<></>)
                        },
                        {
                            label: t("YesConfirm"),
                            func: () => {
                                updateList(item, type);
                                saveList(input, type);
                                setAlertDialog(<></>)
                            }
                        }
                    ]}
                />
            )
        }
        else {
            await saveList(input, type);
            setAlertDialog(<></>)
        }
    }

    const saveList = async (input, type='allow') => {
        const data = {
            list: type,
        };
        
        var masked = input.split("/");
        var detection = "Domain";
        if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
            detection = "IPv4";
            data.type = "cidr";
            data.cidr = input;
        }
        else if (masked[0].indexOf(":") > 0) {
            detection = "IPv6";
            data.type = "cidr";
            data.cidr = input;
        }
        else {
            data.type = "domain";
            const t = input.split(".");
            if (t[0] === "*") {
                data.domainWildcard = true;
                t.shift();
                data.domain = t.join(".");
            }

            else
                data.domain = input;
        }

        const ret = await kernel.api.post('/dohzel/ablist/create', data)
        if (!ret || ret.error) {
            notification.error({
                message: t("Audit.errorAddingRule"),
                description: ret.error
            });
            return;
        }

        setLoaded(!loaded);
    }

    const removeFromList = async (item, type) => {
        const domain = type == 'allow' ? domains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) : blockDomains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain);
        const data = { id: domain.id };
        const ret = await kernel.api.post(`/dohzel/ablist/remove`, data);
        if (!ret || ret.error) {
            notification.error({
                message: t("Audit.errorDeletingRule"),
                description: ret.error
            });
            return;
        }
        setLoaded(!loaded);
    }

    const updateList = async (item, type) => {
        const domain = (type == 'allow') ? blockDomains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) : domains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain);
        const data = {
            id: domain.id,
            activate: !domain.activate
        };
        setLoading(true);
        const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
        setLoading(false);
        if (!ret || ret.error) {
            notification.error({
                message: t('ListManagement.updateError'),
                description: ret.error
            });
            return;
        }

        setLoaded(!loaded);
    };

    const filterChanged = (checkedValues) => {
        console.log('checked = ', checkedValues);
        setFilters(checkedValues);
    };

    const { t, i18n, ready } = useTranslation();
    if (ready) {
        return (
            <Content back="/dashboard" backTransition="outRight" topAlign={true} style={{justifyContent: 'flex-start', marginTop: 45}}>
                <div id="page"  className='container' style={{ overflowY: 'scroll', maxHeight: '90vh', marginTop: 20 }}>

                    <div className="audit">
                        <Title bold centered>{t("Audit.title")}</Title>
                        <div style={{margin: 30}}>
                            <h4 style={{textAlign: 'center'}}>
                                <Checkbox
                                    id="passing"
                                    onChange={event => { 
                                        event.target.checked ? 
                                        setFilters([...filters, 'passing'])
                                        :
                                        setFilters(filters?.filter(filter => filter != 'passing'))
                                    } }
                                    modifier='' value='passing' defaultChecked={true} 
                                />
                                <span htmlFor="passing">Passing</span>
                                <Checkbox
                                    style={{marginLeft: 10}}
                                    id="blocking"
                                    onChange={event => { 
                                        event.target.checked ? 
                                        setFilters([...filters, 'blocking'])
                                        :
                                        setFilters(filters?.filter(filter => filter != 'blocking'))
                                    } }
                                    modifier='' value='blocking' defaultChecked={true} 
                                />
                                <span htmlFor="blocking">Blocking</span>
                                <Checkbox
                                    style={{marginLeft: 10}}
                                    id="detecting"
                                    onChange={event => { 
                                        event.target.checked ? 
                                        setFilters([...filters, 'detecting'])
                                        :
                                        setFilters(filters?.filter(filter => filter != 'detecting'))
                                    } }
                                    modifier='' value='detecting' defaultChecked={true} 
                                />
                                <span htmlFor="detecting">Detecting</span>
                            </h4>
                            {/* <Checkbox.Group style={{color: '#FFFFFF', fontSize: 20, fontWeight: '800'}} options={filterOptions} defaultValue={filterOptions} onChange={filterChanged} /> */}
                        </div>
                        {
                            loading === true ?
                                <div style={{ padding: 10, marginTop:20 }}>
                                    <Skeleton active title={false} paragraph={{ rows: 10 }} />
                                </div>
                            :
                            <>
                                <div className='audit_table'>
                                    {
                                        table.map((item) => {
                                    return (
                                        <div key={`row-${item.key}`} style={{
                                            color: "#152231",
                                            borderLeft: LogsColors[item.policy],
                                            padding: 10,
                                            //margin: 5,
                                            marginBottom: 10,
                                            borderRadius: 10,
                                            background: "linear-gradient(0deg, rgba(236,241,240,1) 0%, rgba(254,255,255,1) 100%)",
                                            width: '100%'
                                        }}
                                        onClick={() => collapseLog(item)}
                                        >
                                            <div style={{ fontWeight: 700, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                <p class="domain">{item.domain}</p>
                                                <Icon style={{color: "#000000", marginRight: 5}} icon={item.collapsed ? 'fa-chevron-down' : 'fa-chevron-right'} />
                                            </div>
                                            {
                                                !item.collapsed &&
                                                <>
                                                    <div style={{ fontSize: "0.7em", fontWeight: 400, paddingTop: "5px", marginBottom:10 }}>
                                                        {formatText(item)}
                                                    </div>
                                                    <div style={{ fontSize: "0.6em", fontWeight: 400 }}>
                                                        {item?.alert?.reasons.map((tag) => {
                                                            return (<Tag bordered={false} key={`${item.id}-${tag}`} color={tags?.find(t => t.name == tag)?.color}>{tag}</Tag>)
                                                        })}
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.collapsed &&
                                                <div style={styles.collapseContent}>
                                                        <LogInfo label={`${t("Audit.domain")}:`} value={item.domain} />
                                                        <LogInfo label={`${t("Audit.date")}:`} value={format(item.date)} />
                                                        <LogInfo label={`${t("Audit.dns")}:`} value={item.recordType} />
                                                        <LogInfo label={`${t("Audit.ip")}:`} value={item.contactIP} />
                                                        <LogInfo label={`${t("Audit.protocol")}:`} value={item.protocol} />
                                                        <LogInfo label={`${t("Audit.action")}:`} value={item?.alert?.decision} />
                                                        <LogInfo label={`${t("Audit.device")}:`} value={item?.device} />
                                                    {
                                                        item.policy == 'blocking' && 
                                                        <div style={styles.logInfoStyle}>
                                                            <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                <Col width={'25%'}>
                                                                    <span>{t("Audit.reasons")}: </span>
                                                                </Col>
                                                                <Col width={'75%'}>
                                                                    <div style={{ fontSize: "0.6em", fontWeight: 400, marginTop: -5 }}>
                                                                        {item?.alert?.reasons.map((tag) => {
                                                                            return (<Tag bordered={false} key={`${item.id}-${tag}`} color={tags?.find(t => t.name == tag)?.color}>{tag}</Tag>)
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                item.collapsed && (item.policy === 'blocking' || item.policy === 'detecting') && !domains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) &&
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 10}}>
                                                    <Button width={'80%'} color={'rgba(0, 60, 91, 1)'} onClick={() => add(item.domain, 'allow', item)} style={{/* marginLeft: 20, paddingTop: 0, paddingBottom: 0 */ }} modifier="cta">
                                                        {t('Audit.allowButton')}
                                                    </Button>
                                                </div>
                                            }
                                            {
                                                item.collapsed && (item.policy === 'blocking' || item.policy === 'detecting') && domains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) &&
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 10}}>
                                                    <Button color={'rgba(0, 60, 91, 1)'} onClick={() => removeFromList(item, 'allow')} /* style={{ marginLeft: 20, paddingTop: 0, paddingBottom: 0, background: '#c0392b' }} */ modifier="cta">
                                                    {t("Audit.removeAllowButton")}
                                                </Button>
                                                </div>
                                            }
                                            {
                                                item.collapsed && (item.policy === 'passing' || item.policy === 'detecting') && !blockDomains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) &&
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 10}}>
                                                    <Button width={'80%'} color={'#d35400'} onClick={() => add(item.domain, 'block', item)} style={{border: '1px solid #b90000' }} modifier="cta">
                                                        {t('Audit.blockButton')}
                                                    </Button>
                                                </div>
                                            }
                                            {
                                                item.collapsed && (item.policy === 'passing' || item.policy === 'detecting') && blockDomains?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain) &&
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 10}}>
                                                    <Button color={'#d35400'} style={{border: '1px solid #b90000'}} onClick={() => removeFromList(item, 'block')} /* style={{ marginLeft: 20, paddingTop: 0, paddingBottom: 0, background: '#c0392b' }} */ modifier="cta">
                                                    {t("Audit.removeBlockButton")}
                                                </Button>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                                    }
                                </div>
                            </>
                        }

                    </div>
                    {alertDialog}
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}


const styles = {
    collapseTitle: {
        float: 'left', 
        textAlign: 'left', 
        marginBottom: 20, 
        marginTop: 20,
        fontSize: 25,
        fontWeight: 900, 
        width: '90%'
    },
    collapseContent: {
        marginLeft: 10,
        marginRight: 10,
        marginTop:20,
        marginBottom:20,
        color: 'black'
    },
    detailInfo: {
        fontWeight: 600,
    },
    subTitle: {
        margin: 20,
        height: '15vh'
    },
    logInfoStyle: {
        marginBottom: 10
    }
}