
import { useEffect, useState } from 'react'
import { Content } from '../Templates'
import { Modal, Space, Button, Skeleton, Affix, notification } from 'antd'
import { Transition } from '../App'
import { format } from 'timeago.js'
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useNavigationType } from 'react-router-dom';

export function Privacy({ kernel }) {
    const [loaded, setLoaded] = useState(true)

    const { t, i18n, ready } = useTranslation();

    const navigate = useNavigate();
    const navigationType = useNavigationType(); 
    const hasPrevious = navigationType === 'POP';

    if (ready) {
        return (
            <Content back={hasPrevious ? '' : '/settings'} backTransition="outRight" nofooter={true} topAlign={true} style={{justifyContent: 'start', marginTop: 40, height: 'calc(100vh - 44px)'}}>
                <div className='container' style={{ overflowY: 'scroll'}}>


                    <div className="audit" style={{ maxWidth: 700, margin: "0 auto", fontWeight: 500, marginTop: 20, marginBottom: 20}}>

                        <h2>Privacy Policy</h2>
                        <p>
                            This privacy policy was updated last on April 26, 2024.
                        </p>

                        <h3>1. Introduction</h3>
                        <p>
                            At Hafnova we value your privacy and are committed to protecting and processing your personal information responsibly.
                        </p>
                        <p>
                            This privacy statement describes how Hafnova collects, uses, and shares personal information about consumers and other individuals within our clients, business partners, supplier and other organizations with which Hafnova has or contemplates a business relationship. It applies to Hafnova Corporation and Hafnova subsidiaries except where a subsidiary presents its own statement without reference to Hafnova’s.
                        </p>
                        <p>
                            Where we provide products, services, or applications as a business-to-business provider to a client, the client is responsible for the collection and use of personal information while using these products, services, or applications. This collection and use is covered by the client’s privacy policy, unless otherwise described. Our agreement with the client may allow us to request and collect information about authorized users of these products, services, or applications for reasons of contract management. In this case, this privacy statement, or a supplementary privacy notice, applies.
                        </p>
                        <p>
                            We may provide additional data privacy information by using a supplementary privacy notice.
                        </p>

                        <h3>2. Personal Information We Collect and Use</h3>

                        <p>
                            This section describes the various types of information that we collect and how we use it.
                        </p>

                        <p>
                            The information that we collect and use may include profile information, interactions on webpages, marketing preferences, information to investigate malicious activities, recordings or transcripts of your conversations with us for support purposes, information to improve our business operations, and more.
                        </p>

                        <h2>2.1. Your Account</h2>

                        <p>
                            You can <a style={{color: "#08ffc9"}} href="https://app.hafnova.com/session/register">create an account</a> with Hafnova. Registering an account provides Hafnova with your name, email address, and country or region of residence. We may require an account for certain services, such as the use of Hafnova Applications and Services.
                        </p>
                        <p>
                            We may also store your details from business contact information that you provide to us, or that we collect from your organization, our Business Partners, or our suppliers.
                        </p>

                        <h2>2.2. Hafnova Websites</h2>

                        <p>
                            Our websites offer ways to communicate with you about us, our products, and services. The information that we collect on websites is used to provide you with access to the website, to operate the website, to improve your experience, and to personalize the way that information is provided to you. If you visit our websites without logging in with an <a style={{color: "#08ffc9"}} href="https://app.hafnova.com/session/login">account</a>, we may still collect information that is connected to your website visit.
                        </p>
                        <p>
                            For more information on the technologies that we use to collect website information, and setting your preferences, see our <a style={{color: "#08ffc9"}} href="https://www.hafnova.com/cookie-policy/">Cookie Policy</a>.
                        </p>

                        <h2>2.3. Hafnova Applications and Services</h2>

                        <p>
                            Our services include hardware, software, software “as-a-service” (cloud), desktop applications, mobile applications. We collect information about the use of these services, such as pages you view or your interactions on that page, to improve and develop our services and to generate technical and market insights. We may require an account for the use of our services (see <a style={{color: "#08ffc9"}} href="https://app.hafnova.com/session/login">Your Account</a>).
                        </p>

                        <h2>2.4. Marketing</h2>

                        <p>
                            Subject to your preferences, we use the information that we collect to communicate with you about relevant products, services, and offerings. We also use this information to personalize your online experience with our content and advertisements and to develop internal marketing and business intelligence. To set or update your preferences with regards to marketing communications by email, phone, or postal, visit <a style={{color: "#08ffc9"}} href="https://app.hafnova.com/session/login">your account</a>. You may also submit an opt-out request, or select Unsubscribe at the bottom of each marketing email. To review or set your preferences regarding the information that we collect about you online on our websites, see our <a style={{color: "#08ffc9"}} href="https://www.hafnova.com/cookie-policy/">Cookie Policy</a>.
                        </p>

                        <h2>2.5. Contractual Relationships</h2>

                        <p>
                            A contractual relationship is created when you use or order a trial, or a product or service from us. While we mainly provide our products and services to businesses, individuals may also enter into an agreement with us directly as a client. We may collect any information that is reasonably necessary to prepare for, enter, and fulfill, the contractual agreement.
                        </p>

                        <h2>2.6. Support Services</h2>

                        <p>
                            When you contact us to request support, including through LiveChat, we collect your contact information, problem description, and possible resolutions. We record the information that is provided to handle the support query, for administrative purposes, to foster our relationship with you, for staff training, and for quality assurance purposes.
                        </p>

                        <h2>2.7. Protecting You and Hafnova</h2>

                        <p>
                            We may collect and use information to protect you and Hafnova from IT security threats and to secure the information that we hold from unauthorized access, disclosure, alteration, or destruction. This includes information from our IT access authorization systems, such as log-in information.
                        </p>

                        <h2>2.8. Hafnova Locations</h2>

                        <p>
                            When you visit an Hafnova location, we collect your name or business contact information (see <a style={{color: "#08ffc9"}} href="https://app.hafnova.com/session/login/">Your Account</a>), and, in some cases, information from a government issued ID. This information is collected for access management and to protect the security and safety of our locations and employees.
                        </p>

                        <h2>2.9. Recruitment and Former Employees</h2>

                        <p>
                            We are constantly searching for new talent for our organization, and we collect information about job applicants or prospective candidates from several sources. When an employee leaves Hafnova, we continue to process information that is related to them for any remaining business, contractual, employment, legal, and fiscal purposes, including the management of pensions to the extent handled by Hafnova.
                        </p>

                        <h2>2.10. Conducting our Business Operations</h2>

                        <p>
                            We collect and use information to improve our business operations, systems, and processes. For example, information may be used to conduct, maintain, audit, and optimize our operations, to protect our assets and employees, for product development, and to defend our rights.
                        </p>

                        <h2>2.11. Cookies and Similar Technologies</h2>

                        <p>
                            When you visit our websites, cloud and online services, software products, or view our content on certain third-party websites, we collect information regarding your connection and your activity by using various online tracking technologies, such as cookies, web beacons, Local Storage, or HTML5. Information that is collected with these technologies may be necessary to operate the website or service, to improve performance, to help us understand how our online services are used, or to determine the interests of our users. We use advertising partners to provide and assist in the use of such technologies on Hafnova and other sites.
                        </p>

                        <h3>3. Sharing Personal Information</h3>

                        <p>
                            We may share your personal information internally and externally with suppliers, advisors, or Business Partners for Hafnova’s legitimate business purposes, and only on a need-to-know basis. This section describes how we share information and how we facilitate that sharing.
                        </p>

                        <h2>3.1. How We Share Personal Information</h2>

                        <p>
                            We shall only share personal data with third parties when and as far as we are legally permitted to do so. When we share data with third parties, we protect the data and comply with our data protection, confidentiality and security standards by means of e.g. security mechanisms, contractual and organizational arrangements.
                        </p>
                        <p>
                            If we decide to sell, buy, merge, or otherwise reorganize businesses in some countries, such a transaction may involve disclosing some personal information to prospective or actual business purchasers, or the collection of personal information from those selling such businesses.
                        </p>

                        <h2>3.2. Facilitating International Transfers</h2>

                        <p>
                            Your personal information may be transferred to or accessed by Hafnova subsidiaries and third parties globally. Hafnova complies with laws on the transfer of personal information between countries to keep your personal information protected, wherever it may be.
                        </p>
                        <p>
                            We have implemented various safeguards including:
                            <br />
                            <br />
                            <ul>
                                <li>Contractual Clauses, such as those approved by the EU Commission and accepted in several other countries. You can request a copy of the EU Standard Contractual Clauses (EU SCCs) by selecting Contact Usin the header of this page.</li>
                                <li>Data Privacy Framework. Where applicable, certain designated Hafnova services (for example, Hafnova Infrastructure-as-a-Service, Platform-as-a-Service, Software-as-a-Service, and select other hosted offerings) are certified to comply with the Data Privacy Framework.</li>
                                <li>Binding Corporate Rules for Controllers (Hafnova BCR-C).</li>
                            </ul>
                        </p>

                        <h3>4. Controller and Representative Information</h3>

                        <p>
                            Hafnova does business through its subsidiaries worldwide. The privacy laws in some countries consider a Controller to be the legal entity (or natural person) who defines the purposes for which the processing of personal information takes place and how that information is processed. Parties that are involved in processing operations on behalf of a Controller may be designated as Processors. Designations and associated obligations differ, depending on the jurisdiction.
                        </p>
                        <p>
                            Where this is relevant for the privacy laws in your country, the Controller of your personal information is Hafnova’s main subsidiary in your country or region, unless International Business Machines Corporation (Hafnova Corp.) or another Hafnova subsidiary identifies itself as the Controller for a specific interaction with you.
                        </p>
                        <p>
                            Where Hafnova Corp. or a subsidiary it controls is required to appoint a legal representative, the following representatives have been appointed
                        </p>

                        <h2>4.1. Data controller and contact information</h2>

                        <p>
                            The data controller responsible for the processing of your personal data as described in this Privacy Statement is Hafnova SA. You will be informed in case of a different data controller deciding about the processing of your data.
                        </p>

                        <p>
                            We have appointed a Data Protection Officer. If you have any questions about this Privacy Statement or about how and why we process personal data, please contact our Data Protection Officer at:
                            <br />
                            <br />
                            Hafnova SA
                            <br />
                            Data Protection Officer
                            <br />
                            Chemin du Bois de Vaux 3
                            <br />
                            1007 Lausanne
                            <br />
                            Switzerland
                            <br />
                            <br />
                            Email: <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">info@hafnova.com</a>
                        </p>

                        <h3>5. Information Security</h3>

                        <p>
                            Information Security is a pilar of Hafnova. Hafnova is accountable to its people, clients, partners, suppliers and other stakeholders to protect information that is entrusted to them.
                        </p>
                        
                        <p>
                            We are constantly developing to safeguard the confidentiality, integrity and availability of the information and technology assets used by Hafnova and its customers. Hafnova is aligned with the industry standard ISO/IEC 27001:2022.
                        </p>
                        
                        <p>
                            To protect your personal information from unauthorized access, use, and disclosure, we implement reasonable physical, administrative, and technical safeguards to keep personal information private while in transit or at rest. We also require our Business Partners, suppliers, and third parties to implement appropriate safeguards, such as contract terms and access restrictions, to protect information from unauthorized access, use, and disclosure.
                        </p>

                        <h3>6. Data retention</h3>
            
                        <p>
                            We retain the personal data processed for as long as is considered necessary for the purpose for which it was collected and described above (including as required by applicable laws or regulations).
                        </p>

                        <p>
                            We only retain personal information as long as necessary to fulfill the purposes for which it is processed, or to comply with legal and regulatory retention requirements. Legal and regulatory retention requirements may include retaining information for:
                            <br />
                            <br />
                            <ul>
                                <li>audit and accounting purposes,</li>
                                <li>statutory retention terms,</li>
                                <li>the handling of disputes,</li>
                                <li>and the establishment, exercise, or defense of legal claims in the countries where we do business.</li>
                            </ul>
                        </p>

                        <p>
                            We retain any contractual relationship information for administrative purposes, legal and regulatory retention requirements, defending Hafnova rights, and to manage Hafnova’s relationship with you. The information that is provided in a supplementary privacy notice may provide more detailed information on applicable retention terms.
                        </p>

                        <p>
                            When personal information is no longer needed, we have processes in place to securely delete it, for example by erasing electronic files and shredding physical records.
                        </p>

                        <p>
                            In the absence of specific legal, regulatory or contractual requirements, our baseline retention period for records and other documentary evidence created in the provision of services is 10 years.
                        </p>

                        <h3>7. Individual’s rights</h3>

                        <p>
                            Hafnova makes every practical effort to avoid excessive or irrelevant collection of data. If you believe that we have collected excessive information, we encourage you to contact us to raise any concerns.
                        </p>

                        <h2>7.1. Access to personal data</h2>

                        <p>
                            You have a right of access to personal data held by us as a data controller. This right may be exercised by <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">emailing</a> us. We aim to respond to any requests for information promptly and, in any event, within the legally required time limit.
                        </p>

                        <h2>7.2. Amendment of personal data</h2>

                        <p>
                            To update personal data submitted to us, you may <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">email</a> us or, where appropriate, contact us via the relevant website registration page or by amending the personal details held on relevant applications with which you registered. When Hafnova retains personally identifiable information, Hafnova assumes responsibility for keeping an accurate record of the information once the data subject has submitted and verified the data. Hafnova does not assume responsibility for verifying the ongoing accuracy of the content of personal data. When feasible in practice, if Hafnova is informed that any personal data collected are no longer accurate, Hafnova shall make appropriate corrections based on the updated information provided by the data subject. When requested and legally permitted, Hafnova shall delete identifying information from current operational systems.
                        </p>

                        <h2>7.3. Withdrawal of consent</h2>

                        <p>
                            Where we process personal data based on consent alone, individuals have a right to withdraw consent at any time. To withdraw consent to our processing of your personal data please <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">email</a> us or, to stop receiving an email from a Hafnova marketing list, please click on the ‘unsubscribe’ link in the email you received from us.
                        </p>

                        <h2>7.4. Erasure/deletion of personal data</h2>

                        <p>
                            You have the right to request that Hafnova deletes your personal data if there is no legal or regulatory requirement for Hafnova to keep your data (such as retention obligations). To request the deletion of your personal data, please send an <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">email</a> ideally together with an identification document and the reasons why you wish us to delete the data.
                        </p>

                        <h2>7.5. Right to lodge a complaint with a supervisory authority</h2>

                        <p>
                            You have the right of appeal to a data protection supervisory authority if you believe that the processing of your personal data violates applicable data protection law. For Switzerland this is Federal Data Protection and Information Commissioner (FDPIC), Feldeggweg 1, 3003 Bern, Tel.: +41 (0)58 462 43 95 (<a style={{color: "#08ffc9"}} href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html">Link</a>).
                        </p>

                        <h2>7.6. Other data subject rights</h2>

                        <p>
                            As well as the rights referred to above, individuals may have other rights in relation to the personal data we hold, such as the right to restrict or object to our processing of personal data and the right to data portability. If you wish to exercise these rights, please send an <a style={{color: "#08ffc9"}} href="mailto:info@hafnova.com">email</a>.
                        </p>

                        <h3>8. Legal Basis</h3>

                        <p>
                            In some jurisdictions, the lawful handling of personal information is subject to a justification, sometimes referred to as legal basis. The legal bases that we rely on for the lawful handling of your personal information vary depending on the purpose and applicable law.
                        </p>

                    </div>

                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}