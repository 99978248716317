import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import Paragraph from './../../components/Paragraph';
import Button from './../../components/Button';
import { useTranslation } from "react-i18next";

export function Free({ kernel }) {

    const navigate = useNavigate()

    const { t, i18n, ready } = useTranslation();

    if (ready) {
        return (
            <Content back="/install/EnrollCode" backTransition="outRight" topAlign={true}>
                <div id="page">
                    <div style={styles.container} className='container'>
                        <Logo />  
                        <div style={styles.title}>
                            <Title centered>{t('Free.title')}</Title>
                        </div>
                        
                        <div style={styles.buttonContent}>
                                <Button onClick={() => navigate('/install/EnrollCode')} modifier="cta">
                                    {t('Free.backButton')}
                                    </Button>
                                <Button onClick={() => {
                                    console.log("/install/Tag")
                                    navigate('/install/Tag')
                                } } modifier="cta">
                                    {t('Free.continueButton')}
                                </Button>
                        </div>

                        <div style={styles.content}>
                            <Paragraph>
                                {t('Free.content')}
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 25,
    },
    title: {
        fontWeight: 600,
        fontSize: 40
    },
    input: {
        color: '#FFFFFF',
        fontSize: 40,
    },
    centerContent: {
    },
    content: {
        fontSize: 30,
        margin: 10,
        maxWidth: 600, 
        margin: 'auto',
        marginTop: 20,
        marginBottom:50,
    },
    buttonContent: {
        marginTop:30,
        justifyContent:'space-between',
        display: 'flex',
        gap:20
    },
    questionContainer: {
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
        margin: 20
    }
}