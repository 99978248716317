
import { Segmented, notification } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { Icon, Input } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import '../../onsen-css-components.css';
import BigText from './../../components/BigText';
import Button from './../../components/Button';
import Paragraph from './../../components/Paragraph';
import CustomActionSheet from './ActionSheet';
import CustomAlertDialog from './AlertDialog';
import Logo from '../../components/Logo';
import { useTranslation, Trans } from "react-i18next";

const items = [
    {
      key: '1',
      label: 'Tab 1',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Tab 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab 3',
      children: 'Content of Tab Pane 3',
    },
  ];
  

const logs = [
    {
        name: 'Forward all logs to the profile.',
        value: 'all'
    },
    {
        name: 'Forward only security to profile.',
        value: 'onlySecurity'
    },
    {
        name: 'Do not forward to profile.',
        value: 'none'
    }
]

const Connexion = ({ kernel }) => {
    const [loaded, setLoaded] = useState(true)
    const navigate = useNavigate()
    const [profils, setProfils] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [profileName, setProfileName] = useState(kernel.api.profileName)
    const [actionSheet, setActionSheet] = useState(<></>);
    const [alertDialog, setAlertDialog] = useState(<></>);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        setInterval(() => {
            forceUpdate();
            // kernel.api.beat()
        }, 1000)
        if (!kernel.api.token)
            return (navigate("/install/welcome"))

        setLoading(true)
        var interval = null

        const loader = async () => {
            var response = await kernel.api.get("/user/me", { userRequest: true })
            if (response.error) {
                // notification.error({
                //     message: `Error fetching information`,
                //     description: <>{response.error}</>,
                //     placement: "topRight",
                // })
                interval = setTimeout(loader, 10000);
                return
            }
            setUser(response.data);
            setLoading(false);
            setConnected(true);
        }
        loader()

        return () => {
            if (interval) clearTimeout(interval)
        }

    }, [loaded])

    useEffect(() => {
        setProfileName(kernel.api.profileName);
    }, [kernel.api.profileName, kernel.api.deviceManagement, loaded])

    const disconnecting = () => {
        notification.success({
            message: `Disconnected`,
            description: "Your session has been closed",
        })
        kernel.session.store("");
        setAlertDialog(<></>);
        setConnected(false);
    }

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <Content back="/dashboard" backTransition="outRight" nofooter={true} topAlign={true} style={{justifyContent: 'start', height: 'calc(100vh - 44px)'}}>
                <div id="page" className='container' style={{ textAlign: "center", margin: 'auto', maxWidth: 600 }}>
                        
                        <div>
                            <Logo />  
                            <div style={{marginBottom: 30, marginTop: 20}}>
                                <BigText centered bold>{t("Settings.connexionTitle")}</BigText>
                            </div>
                        </div>
                        <>
                            {
                                !connected && kernel.api.deviceManagement != 'mdm' &&
                                <div style={{marginTop:20, border: "none"}} className='form-container setting_card'>
                                        {/* <p className='setting_card_title'>{t("Settings.loginTitle")}</p> */}
                                        <div style={{display:'flex', marginTop: 20}}>
                                            {/* <Button onClick={() => {
                                                    // setLoginProcess(true);
                                                    navigate('/session/login')
                                                }} style={{ margin: 'auto' }} modifier="cta">
                                                {t("Settings.login")}
                                            </Button> */}
                                            <Button
                                                    style={{width: '50%'}}
                                                    modifier="cta"
                                                    onClick={() => {
                                                        // setLoginProcess(true);
                                                        navigate('/session/login')
                                                    }}
                                                >
                                                    {t("Settings.login")}
                                                </Button>
                                                <Button 
                                                    onClick={() => {
                                                        // setLoginProcess(true);
                                                        navigate('/session/signup')
                                                    }} style={{ marginLeft: 20, width: '50%' }} modifier="cta">
                                                    {t("Settings.createAccount")}
                                                </Button>
                                        </div>
                                    </div>
                            }

                            {/* {
                                !connected && kernel.api.deviceManagement != 'mdm' &&
                                <div style={{marginTop:20}} className='form-container setting_card'>
                                        <p className='setting_card_title'>{t("Settings.signupTitle")}</p>
                                        <div style={{display:'flex', marginTop: 20}}>
                                            <Button onClick={() => {
                                                    // setLoginProcess(true);
                                                    navigate('/session/signup')
                                                }} style={{ margin: 'auto' }} modifier="cta">
                                                {t("Settings.createAccount")}
                                            </Button>
                                        </div>
                                    </div>
                            } */}

                            {
                                !profileName && kernel.api.deviceManagement != 'mdm' &&
                                <div style={{marginTop:20, border: "none"}} className='form-container setting_card'>
                                        {/* <p className='setting_card_title'>{t("Settings.profileTitle")}</p> */}
                                        <div style={{display:'flex', marginTop: -30}}>
                                            <Button onClick={() => {
                                                    // setLoginProcess(true);
                                                    localStorage.setItem('enrollCodeBack', '/settings');
                                                    navigate('/install/enrollCode')
                                                }} style={{ margin: 'auto' }} modifier="cta">
                                                {t("Settings.enrollDevice")}
                                            </Button>
                                        </div>
                                    </div>
                            }

                            {
                                connected && kernel.api.deviceManagement != 'mdm' &&
                                <div className='setting_card'>
                                        <p className='setting_card_title'>{t("Settings.accountInformation")}</p>
                                        <Paragraph>{t("Settings.accountFirstname")}: {user?.firstName}</Paragraph>
                                        <Paragraph>{t("Settings.accountLastname")}: {user?.lastName}</Paragraph>
                                        <Paragraph>{t("Settings.accountSharedToken")}: {user?.shareCode}</Paragraph>
                                        <Paragraph>{t("Settings.accountEmail")} : {user?.emails?.map((email) => (
                                                <Paragraph>{email.email} {email.activated ? `(${t("Settings.accountEmailVerified")})` : `(${t("Settings.accountEmailNotVerified")})`}</Paragraph>
                                            ))
                                        }</Paragraph>
                                </div>
                            }

                            {
                                connected && kernel.api.deviceManagement != 'mdm' &&
                            
                                    <div style={{display: 'flex', marginTop:20}}>
                                        <Button color={'red'} style={{margin: 'auto'}} modifier="cta" onClick={() => {
                                            setAlertDialog(
                                                <CustomAlertDialog title={'Logout!'} subTitle={'Are you sure ?'} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setActionSheet={setActionSheet} setAlertDialog={setAlertDialog}
                                                    buttons={[
                                                        {
                                                            label: "No",
                                                            color: 'red',
                                                            func: () => setAlertDialog(<></>)
                                                        },
                                                        {
                                                            label: "Yes",
                                                            func: disconnecting
                                                        }
                                                    ]}
                                                />
                                            )
                                        }}>
                                            {t("Settings.logout")}
                                        </Button>
                                    </div>
                            }

                        </>

                        {alertDialog}
                        {actionSheet}
                    
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    button: {
        width: '100%',
        height: 60,
        fontSize: 20,
        marginBottom: 20,
        marginTop: 20
    },

    container: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 25,
    },
    title: {
        marginTop: 80,
        fontWeight: 700,
        fontSize: 40
    },
    subTitle: {
        marginTop: 30,
        marginBottom:30,
        fontSize: 22,
        textAlign: 'center'
    },
    centerContent: {
        position: 'absolute',
        top: '30%',
        margin: 30,
    },
    content: {
        fontSize: 30
    },
    buttonContent: {
        margin: 30,
        display: 'block'
    },
    questionContainer: {
    },
    forwarding: {
        //background:'green',
        //overflowY: 'scroll',
        //overflowX: 'clip',
        fontSize: 20,
        //marginTop: 20,
        //maxWidth: 600 
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    error: {
        position: 'relative',
        top: -10,
        color: 'red',
        fontStyle: 'italic'
    }
}

export default Connexion;
// 2765: z-index = 9999