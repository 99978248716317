import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import { useTranslation } from "react-i18next";

export function Enroll({ kernel }) {

    const navigate = useNavigate()

    const { t, i18n, ready } = useTranslation();
  
    if (ready) {
        return (
            <Content back="/install/welcome" backTransition="outRight" topAlign={true}>
                <div className='container' id="page" style={{...styles.container, height:'90dvh', width:'90dvw', paddingBottom: 40, paddingTop: 80}}>
                    <Logo />   
                    <div style={styles.title}>
                        <Title centered>{t('Enroll.title')}</Title>
                    </div>

                    <div className="btn-group" style={styles.buttonContent}>
                        <div style={styles.button}>
                            <Button onClick={() => {
                                localStorage.setItem('installProcess', JSON.stringify({ codeType: 'free', noCode: true }));
                                localStorage.setItem('enrollCodeBack', '/install/enroll');
                                navigate('/install/tag')}
                            } modifier="cta">
                                {t('Enroll.noCodeButton')}
                            </Button>
                        </div>
                        <div style={styles.button}>
                            <Button onClick={() => {
                                localStorage.setItem('enrollCodeBack', '/install/enroll');
                                navigate('/install/enrollCode')
                                }
                            } modifier="cta">
                                {t('Enroll.codeButton')}
                            </Button>
                        </div>
                    </div>

                    <div style={styles.content}>
                        <Paragraph centered>
                            {t('Enroll.content')}
                        </Paragraph>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    title: {
        //marginTop: 100,
        fontWeight: 700,
        marginBottom: 30,
    },
    centerContent: {
        marginTop: '30px !important'
    },
    content: {
        fontSize: 30,
        marginBottom: 0,
        marginTop: 20
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        //height: '90vh',
        flexDirection:'column',
        textAlign: 'center',
        maxWidth: 600,
    },
    buttonContent: {
        marginBottom: '30px !important',
        display: 'flex',
        gap: 0,
    },
    button: {
        display: 'block'
    }
}